import React, { lazy, Suspense } from "react";
import "@stripe/stripe-js";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
//framer motion is used in the app for some page animations

import "./App.css";
import { Loading } from "./components/Loading";

//lazy-loading components
const Translate = lazy(() =>
  import(/* webpackPrefetch: true */ "./components/Translate")
);
const Maximized = lazy(() =>
  import(/* webpackPrefetch: true */ "./components/Maximized")
);
const HireExperts = lazy(() =>
  import(/* webpackPrefetch: true */ "./components/hire-experts")
);
const UnsupportedLanguage = lazy(() =>
  import(/* webpackPrefetch: true */ "./components/UnsupportedLanguage")
);
const PrivacyPolicy = lazy(() => import("./components/Policies/PrivacyPolicy"));
const TermsAndConditions = lazy(() =>
  import("./components/Policies/TermsAndConditions")
);
const RefundPolicy = lazy(() => import("./components/Policies/RefundPolicy"));
const PaymentCompleted = lazy(() =>
  import("./components/payment-pages/PaymentCompleted")
);
const PaymentCanceled = lazy(() =>
  import("./components/payment-pages/PaymentCanceled")
);
const PaymentFailed = lazy(() =>
  import("./components/payment-pages/PaymentFailed")
);
const TrackYourOrder = lazy(() =>
  import("./components/TrackOrder/TrackYourOrder")
);
const Status = lazy(() => import("./components/TrackOrder/Status"));

//App
function App() {
  const location = useLocation();

  //Wraping all the Lazy-loaded Components with React.Suspense
  //to handle the "waiting" state for these components

  return (
    <div className="App">
      <AnimatePresence mode="wait">
        <Suspense
          fallback={
            <>
              <Loading />
            </>
          }
        >
          <Routes location={location}>
            <Route path="/" element={<Translate />} />
            <Route path="/maximized" element={<Maximized />} />
            <Route
              path="unsupported-language"
              element={<UnsupportedLanguage />}
            />

            <Route path="/hire-experts" element={<HireExperts />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-&-conditions" element={<TermsAndConditions />} />
            <Route path="refund-policy" element={<RefundPolicy />} />
            <Route path="success" element={<PaymentCompleted />} />
            <Route path="cancel" element={<PaymentCanceled />} />
            <Route path="failed" element={<PaymentFailed />} />
            <Route path="track-your-order" element={<TrackYourOrder />} />
            <Route path="status" element={<Status />} />
          </Routes>
        </Suspense>
      </AnimatePresence>
    </div>
  );
}

export default App;
