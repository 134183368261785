import { createContext, useState } from "react";
import { DROPDOWN_OPTIONS } from "../common/data/dropdown";

const TranslationContext = createContext();

function TranslationProvider({ children }) {
  const [inputLanguage, setInputLanguage] = useState(DROPDOWN_OPTIONS[1]);
  //to store the input language
  const [outputLanguage, setOutputLanguage] = useState(DROPDOWN_OPTIONS[0]);
  //to store the output language

  const [supported, setSupported] = useState(false);
  //to store the coolean value if the language pair is supported on hire experts

  const [inputText, setInputText] = useState("");
  // to store the input text

  const [debouncedText, setDebouncedText] = useState("");
  //this deboundText is used so that we dont request for a translation for every single key stroke

  const [translated, setTranslated] = useState("");
  // to store the output text
  const [inputTextWords, setInputTextWords] = useState(0);
  //to store the input word count

  const [isTablet, setIsTablet] = useState(false);
  // to store the boolean value wheather the device is a mobile/tablet

  const [initialStyle, setInitialStyle] = useState({
    scale: 0.9,
    opacity: 0,
    x: -500,
  });
  //for framer motion animation based on the device
  const [animateStyle, setAnimateStyle] = useState({
    scale: 1,
    opacity: 1,
    x: 0,
  });
  //for framer motion animation based on the device

  const TranslationSelected = {
    inputLanguage: inputLanguage,
    outputLanguage: outputLanguage,
  };

  return (
    <TranslationContext.Provider
      value={{
        inputLanguage,
        setInputLanguage,
        outputLanguage,
        setOutputLanguage,
        inputText,
        setInputText,
        translated,
        setTranslated,
        TranslationSelected,
        inputTextWords,
        setInputTextWords,
        initialStyle,
        setInitialStyle,
        animateStyle,
        setAnimateStyle,
        isTablet,
        setIsTablet,
        supported,
        setSupported,
        debouncedText,
        setDebouncedText,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
}

export { TranslationProvider };
export default TranslationContext;
