//this context file contains the data used in the hireexpert pages
import { createContext, useState } from "react";

const HireExpertsContext = createContext();

function HireExpertsProvider({ children }) {
  const [file, setFile] = useState([]); //to store the uploaded file
  const [isUploading, setIsUploading] = useState(false); //for uploading animations n all
  const [uploadedText, setUploadedText] = useState(""); //to store the uploaaded text
  const [uploadedWordCount, setUploadedWordCount] = useState(0); //to store the uploaded word count
  const [name, setName] = useState(""); //to store the name of the user
  const [email, setEmail] = useState(""); //to store the email of the user
  const [contactNumber, setContactNumber] = useState(""); //to store the phone number of the user
  const [country, setCountry] = useState("in"); //to store the country of the user
  const [countryState, setCountryState] = useState(""); //to store the state of the user

  const [isValidNumber, setIsValidNumber] = useState(false); //boolean value of the valid phone number
  const [nameError, setNameError] = useState(false); //to show the name error on screen
  const [emailError, setEmailError] = useState(false); //to show the email error on screen
  const [phoneError, setPhoneError] = useState(false); //to show the name phone on screen
  const [stateError, setStateError] = useState(false); //to show the name state on screen

  const [airtableRecordId, setAirtableRecordId] = useState("");
  //to store the table id of the current user on the database

  const [page, setPage] = useState(1);
  //to store the current page state in the hire experts page
  const [previousPage, setPreviousPage] = useState(0);
  //to store the previous page state in the hire experts page
  const [progressBarClassName, setProgressBarClassName] = useState(
    "title-bar-progress-bar"
  ); //to store the prograssbar class name for animating it accordingly
  const [price, setPrice] = useState(0); //to stote the price value calculated
  const [hours, setHours] = useState(48); //to store the time as per th user selection
  const [selectedPackage, setSelectedPackage] = useState("Intermediate");
  //to store the package as per th user selection
  const [firstVisit, setFirstVisit] = useState(true);
  //to store if the user has visited the payment page for the first time in
  //that session with the current texts for translation

  const [paymentError, setPaymentError] = useState(null); //to store the stripe errors
  const [paymentComplete, setPaymentComplete] = useState(null);
  const [status, setStatus] = useState(4); //to show the status report on the translation progress

  // const [paymentSuccess, setPaymentSuccess] = useState(false);

  const [trackingID, setTrackingID] = useState("");
  //for now we use the airtable id as the tracking id

  const [visited, setVisited] = useState(false);

  const [trackingIdError, setTrackingIdError] = useState(false);

  const [inputLanguageValue, setInputLanguageValue] = useState("");
  const [outputLanguageValue, setOutputLanguageValue] = useState("");
  const [displayWordCount, setDisplayWordCount] = useState(0);

  const [successTime, setSuccessTime] = useState(new Date().toISOString());
  //to store the success time of the paymet to the databse

  const [timeDue, setTimeDue] = useState(new Date().toISOString());
  //to store the due time of the paymet to the databse

  const [timeDueCalculated, setTimeDueCalculated] = useState(false);
  //boolean to know if thr due is claculated or not

  const [dateUpdated, setDateUpdated] = useState(true);
  //boolean to know if the date was updated on the airtable DB
  const [dataLoaded, setDataLoaded] = useState(false);
  //boolean to check if the data was loaded from the airtable DB

  const [rating, setRating] = useState(0);
  //to store thw rating
  const [animateConfetti, setAnimateConfetti] = useState(true);
  //to store the boolean to show confetti animation

  return (
    <HireExpertsContext.Provider
      value={{
        file,
        setFile,
        isUploading,
        setIsUploading,
        uploadedText,
        setUploadedText,
        uploadedWordCount,
        setUploadedWordCount,
        name,
        setName,
        email,
        setEmail,
        country,
        setCountry,
        contactNumber,
        setContactNumber,
        countryState,
        setCountryState,
        isValidNumber,
        setIsValidNumber,
        nameError,
        setNameError,
        emailError,
        setEmailError,
        phoneError,
        setPhoneError,
        stateError,
        setStateError,
        airtableRecordId,
        setAirtableRecordId,
        page,
        setPage,
        previousPage,
        setPreviousPage,
        progressBarClassName,
        setProgressBarClassName,
        price,
        setPrice,
        hours,
        setHours,
        selectedPackage,
        setSelectedPackage,
        firstVisit,
        setFirstVisit,
        paymentError,
        setPaymentError,
        paymentComplete,
        setPaymentComplete,
        status,
        setStatus,
        trackingID,
        setTrackingID,
        visited,
        setVisited,
        trackingIdError,
        setTrackingIdError,
        inputLanguageValue,
        setInputLanguageValue,
        outputLanguageValue,
        setOutputLanguageValue,
        displayWordCount,
        setDisplayWordCount,
        successTime,
        setSuccessTime,
        timeDue,
        setTimeDue,
        dataLoaded,
        setDataLoaded,
        dateUpdated,
        setDateUpdated,
        rating,
        setRating,
        animateConfetti,
        setAnimateConfetti,
        timeDueCalculated,
        setTimeDueCalculated,
      }}
    >
      {children}
    </HireExpertsContext.Provider>
  );
}

export { HireExpertsProvider };
export default HireExpertsContext;
